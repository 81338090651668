import React from 'react'
import Layout from '../Layouts/Layout'
import logout from '../Animations/logout.json'
import Lottie from 'lottie-react'
import {useNavigate} from 'react-router-dom'
import classes from './LogOut.module.css'
import Cookies from 'js-cookie'

const LogOut = () => {

    const navigate = useNavigate();

    const handleLogOut=()=>{
        Cookies.remove('username');
        navigate('/login');
    }

    

  return (
    <Layout>
      <div className={classes.container}>
    <Lottie animationData={logout} className={classes.animation}/>
    <div className={classes.ask}>
    <p className={classes.question}> Are You Sure you want to Log Out?</p>
    
    <button className={classes.button} onClick={handleLogOut}>
        Log Out
    </button>
    </div>
   
    </div>
    </Layout>
  )
}

export default LogOut
