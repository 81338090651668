import logo from './logo.svg';
import './App.css';
import {Routes,Route} from 'react-router-dom'
import Home from './Pages/Home';
import DeactivationLogs from './Pages/DeactivationLogs';
import UserHistory from './Pages/UserHistory';
import Login from './Pages/Login';
import Auth from './Auth/Auth';
import LogOut from './Pages/LogOut';
import Deactivate from './Pages/Deactivate';


function App() {
  return (
   <Routes>
   <Route path='/' element={<Auth><Home/></Auth>}/>
   <Route path='/deactivate-list' element={<Auth><Deactivate/></Auth>}/>

   <Route path='/deactivation-logs' element={<Auth><DeactivationLogs/></Auth>}/>
   <Route path='/history' element={<Auth><UserHistory/></Auth>}/>
   <Route path='/login' element={<Auth><Login/></Auth>} />
   <Route path='/log-out' element={<Auth><LogOut/></Auth>}/>
   </Routes>
  );
}

export default App;
