import React,{useState,useEffect} from 'react'
import Layout from '../Layouts/Layout'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FaMobileAlt } from "react-icons/fa";
import Loader from '../Components/Loader';
import axios from 'axios'
import { get_deactivation_api } from '../Services/api';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import classes from './Home.module.css'

const DeactivationLogs = () => {


    const [tableData, setTableData] = useState([]);
    const [number,setNumber] = useState('');
    const [loading,setLoading] = useState(false);
    const [showTable,setShowTable] = useState(false);


    const handleSubmit=async(e)=>{
        e.preventDefault();
        console.log(number,'gxfcghj')

        setLoading(true);

        if(number == ''){
          toast.error('Please Input Number first!!');
          setLoading(false);
          return ;
        }

        const data = {
          ani:number
        }

        try{
          const res = await axios.post(get_deactivation_api,data)
          console.log(res.data.result)
          if(res.data.result.length == 0){
            toast.success('No Data Found !!')
          

          }

          setTableData(res.data.result);
          setShowTable(true)
        }
        catch(err){
          console.log(err)
          toast.error(err.response.data.message)
        }
        setLoading(false);

        setNumber('');


        setNumber('');
    }




  return (
    <div>
       <Layout>
       <div className="dark:text-white flex flex-col px-2 w-full">
      <div className='lg:hidden md:hidden'>
        <h1 className="flex justify-center items-center text-gray-600 underline font-bold text-lg">Bobble Deactivations</h1>

        </div>
                <h1 className="flex justify-center items-center text-emerald-600 font-bold text-lg"> Check Deactivation Logs</h1>
        <form onSubmit={handleSubmit} className="flex justify-center items-center">
          <div className="flex flex-col  justify-center items-center bg-blue-50 shadow-3xl lg:w-[400px] lg:h-[250px] w-[250px] h-[280px] rounded-lg">
            <div className="flex flex-col gap-3">
              <label className="text-xl lg:mt-2 mt-3 items-start">Enter Number</label>
              <div className="flex flex-row relative">
                <FaMobileAlt className='absolute top-1/2 right-3 transform -translate-y-1/2 text-black'/>
                <input
                  placeholder='Enter Number'
                  name='number'
                  value={number}
                  onChange={(e)=>{setNumber(e.target.value)}}
                  className="border-1 border-gray-100 bg-white rounded-lg lg:px-3 lg:py-2 px-4 py-2 lg:w-[200px] w-[180px]"
                />
              </div>
            </div>

           
            <div className="w-24 lg:mt-6 mt-8 bg-emerald-600 text-white rounded-lg lg:px-3 lg:py-2 px-3 py-1">
              <button type="submit" className='w-full text-center'>Submit</button>
            </div>
          </div>
        </form>
        {loading ? (
          <div>
            <Loader/>
            </div>
        ):
        (
          showTable && (
            <>
              
            <DataTable
           value={tableData}
          stripedRows
          emptyMessage="No data found"
          responsive
          scrollable
          scrollHeight="500px"
          // rows={10}
          // paginator
          tableStyle={{ minWidth: "10rem" }}
          className={classes.tbl}
        >
                          {/* <Column field="id" header="Id" headerStyle={{backgroundColor:'#e9f7f1',padding:'1rem',textAlign:'center'}}></Column> */}
          
                  <Column field="ani" header="ANI" headerStyle={{backgroundColor:'#e9f7f1',padding:'1rem',textAlign:'center'}}></Column>
                 
                  <Column field="svc_name" header="SERVICE" headerStyle={{backgroundColor:'#e9f7f1',padding:'1rem',textAlign:'center'}}></Column>

                   <Column field="sub_date_time" header="SUB DATE" headerStyle={{backgroundColor:'#e9f7f1',padding:'1rem',textAlign:'center'}}
                            body={(rowData) => {
                              return rowData.sub_date_time ? rowData.sub_date_time.split('T')[0].split('-').join('/') : 'null';
                            }} 
                          />
                      
                  <Column field="last_billed_date" header="Last Charged" headerStyle={{backgroundColor:'#e9f7f1',padding:'1rem',textAlign:'center'}}
                    body={(rowData) => {
                              return rowData.last_billed_date ? rowData.last_billed_date.split('T')[0].split('-').join('/') : 'null';
                            }} 
                  />
                  <Column  field="unsub_date_time" header="UnsubDate" headerStyle={{backgroundColor:'#e9f7f1',padding:'1rem',textAlign:'center'}}
                   body={(rowData) => {
                              return rowData.unsub_date_time ? rowData.unsub_date_time.split('T')[0].split('-').join('/') : 'null';
                            }} 
                   />
                   
              
                </DataTable>
             
           
            </>
          )
        )}
       


      </div>
    </Layout>
    </div>
  )
}

export default DeactivationLogs
