// const base_url = 'http://localhost:5015';
const base_url ='';

const get_activation_api = `${base_url}/api/get-activation`
export {get_activation_api};


const get_deactivation_api = `${base_url}/api/get-Deactivation`
export {get_deactivation_api}

const get_history_api = `${base_url}/api/get-history`
export {get_history_api}


const get_charging_api = `${base_url}/api/charging`
export {get_charging_api}