import React,{useEffect} from 'react'
import Cookies from 'js-cookie';
import {useNavigate} from 'react-router-dom'


const Auth = ({children}) => {

    const user = Cookies.get('username');
    const navigate = useNavigate();

    useEffect(() => {
        if(!user){
            navigate('/login');
        }
        // else{
        //     navigate('/')
        // }
    }, [])

  return (
    <div>
    {children}
    </div>
  )
}

export default Auth
