import React, { useState } from 'react';
import classes from './Login.module.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  // Define static credentials
  const staticCredentials = {
    admin: 'admin@123',
  };

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if username exists in static credentials and if password matches
    if (staticCredentials[username] && password === staticCredentials[username]) {
      Cookies.set('username', username, { expires: 2 });
      
      toast.success('Login successful');

      // Redirect based on username
      setTimeout(() => {
          navigate('/');
      }, 1000);
    } else {
      toast.error('Invalid username or password');
    }
  };

  return (
    <>
      <ToastContainer/>
      <div className={classes.main_container}>
        <div className={classes.container}>
          <div className={classes.heading}>Sign In</div>
          <form className={classes.form} onSubmit={handleSubmit}>
            <input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              className={classes.input}
              type="text"
              name="username"
              placeholder="UserName"
            />
            <div className={classes.password_container}>
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className={classes.input}
                type={showPassword ? 'text' : 'password'}
                name="password"
                placeholder="Password"
              />
              <span
                className={classes.eye_icon}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            <input className={classes.login_button} type="submit" value="LOGIN" />
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
