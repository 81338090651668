import React,{useState,useEffect} from 'react'
import Layout from '../Layouts/Layout'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FaMobileAlt } from "react-icons/fa";
import Loader from '../Components/Loader';
import axios from 'axios'
import { get_activation_api } from '../Services/api';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import classes from './Home.module.css'

const Deactivate = () => {


    const [tableData, setTableData] = useState([]);
    const [numbers,setNumber] = useState('');
    const [loading,setLoading] = useState(false);
    const [showTable,setShowTable] = useState(false);
    const [results,setResults] = useState([]);
    const [length,setLength] = useState();

    // const [loadingRows, setLoadingRows] = useState({});

    // const getData=async(number)=>{

    //   const data = {
    //     ani:number
    //   }

    //   try{
    //     const res = await axios.post(get_activation_api,data)
    //     console.log(res.data.result)
    //     if(res.data.result.length == 0){
    //       toast.success('No Data Found !!')
    //     }

    //     setTableData(res.data.result);
    //     setShowTable(true)
    //   }
    //   catch(err){
    //     console.log(err)
    //     toast.error(err.response.data.message)
    //   }
    // }

    // console.log(results,'=-=-=-=-=-=-=-=-=-=-=-==-')


    const handleSubmit=async(e)=>{
        e.preventDefault();
        // setLoading(true);
        // console.log(numbers,'gxfcghj')

        const numberArray = numbers.split('\n');

        setLoading(true);
        console.log(numberArray);

        setLength(numberArray.length)

        const newResults = [];

       

        for (const element of numberArray) {
          const result = await handleUnsubscribe(element); // Wait for each unsubscribe operation to complete
          // console.log(result, 'unsub result');
          newResults.push(result); // Push the result directly (no need to stringify)
      }

        console.log(newResults)
        setResults(newResults);
        // console.log(results)
        setShowTable(true)
        setLoading(false)
    }

    const handleUnsubscribe=async(number)=>{
      // console.log(number,'unsub_data')

      try{
        const res = await axios.post('https://callback.bubblebobble.co.za/api/unsubscribe/number',{ani:number})
        console.log(res)
        // setResults({...results,res.data.result})
        return res.data.result 
      }
      catch(err){
        console.log(err)
      }

      
      
    }



  useEffect(()=>{

    const arr = []

    results.map((item)=>{
      item.map((innerItem)=>{
        arr.push(innerItem);
      })
    })

    // console.log(arr)
    
    setTableData(arr)

  },[results])

    



  return (
    <Layout>
       <div className="dark:text-white flex flex-col px-2 w-[100%]">
      <div className='lg:hidden md:hidden'>
        <h1 className="flex justify-center items-center text-gray-600 underline font-bold text-lg">Bobble Deactivation</h1>

        </div>
                <h1 className="flex justify-center items-center text-emerald-600 font-bold text-lg">Deactivate User List</h1>
        <form onSubmit={handleSubmit} className="flex justify-center items-center"  >
          <div className="flex flex-col  justify-center items-center bg-blue-50 shadow-3xl lg:w-[400px] lg:h-[250px] w-[250px] h-[280px] rounded-lg">
            <div className="flex flex-col gap-3">
              <label className="text-xl lg:mt-2 mt-3 items-start">Enter Numbers</label>
              <div className="flex flex-row relative">
                <textarea id="w3review" name="w3review" rows="50"
                placeholder='Enter Numbers separated by a new line'
                value={numbers}
                className="scroller border-1 border-gray-100 h-[8rem]  bg-white rounded-lg lg:px-3 lg:py-2 px-4 py-2 w-[13rem] lg:w-[20rem]"
                style={{scrollBarWidth:'none'}} 
                onChange={(e)=>{setNumber(e.target.value)}}
                >
                </textarea>
              </div>
            </div>

           

            <div className="w-24 lg:mt-6 mt-8 bg-emerald-600 text-white rounded-lg lg:px-3 lg:py-2 px-3 py-1">
              <button type="submit" className='w-full text-center'>Submit</button>
            </div>
          </div>
        </form>

    {
      loading &&
          
      <p
    className="text-sm text-red-500 w-full items-center text-center break-words"
  >
    **you have entered {length} numbers so wait for some time**
  </p>

    }




        {loading ? (
          <div>
            <Loader/>
            </div>
        ):
        (
          showTable && (
            <div style={{display:'flex',alignItems:'center',justifyContent:'center',width:'100%'}}>

              
            <DataTable
            value={tableData}
          stripedRows
          emptyMessage="No data found"
          responsive  
          scrollable
          scrollHeight="500px"
          rows={10}
          paginator
          tableStyle={{ minWidth: "10rem" }}
          className={classes.tbl}
        >
                          {/* <Column field="id" header="Id" headerStyle={{backgroundColor:'#e9f7f1',padding:'1rem',textAlign:'center'}}></Column> */}
          
                  <Column field="number" header="ANI" headerStyle={{backgroundColor:'#e9f7f1',padding:'1rem',textAlign:'center'}}></Column>
                 
                  <Column field="svc_name" header="SERVICE" headerStyle={{backgroundColor:'#e9f7f1',padding:'1rem',textAlign:'center'}}></Column>

                  <Column field="result" header="RESULT" headerStyle={{backgroundColor:'#e9f7f1',padding:'1rem',textAlign:'center'}}
                      body={(rowData) => {
                              return rowData.result || 'null';
                            }} 
                  />

                  <Column field="error" header="ERROR" headerStyle={{backgroundColor:'#e9f7f1',padding:'1rem',textAlign:'center'}}
                     body={(rowData) => {
                              return rowData.error || 'null';
                            }} 
                  />

                      
                 
                   
              
                </DataTable>
             
              
             
           
            </div>
          )
        )}



      </div>
      <ToastContainer/>
    </Layout>
  )
}

export default Deactivate
